import * as React from "react";
import { FunctionComponent, useState } from "react";
import {
  Button,
  Dropdown,
  Heading,
  Input,
  InputHelper,
  Link,
  Page
} from "../components";
import { useStaticQuery, graphql } from "gatsby";
import { togglePopup } from "../utilities/index";

const Recaptcha = require("react-recaptcha");

const OTHER_DROPDOWN_VALUE = "Other";

const EStatmentCustomerServiceForm: FunctionComponent = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    {
      accountNumberSample: file(name: { eq: "hmc-account-number-sample" }) {
        publicURL
      }
    }
  `);
  const [isOther, setIsOther] = useState(false);
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const recaptchaVerifyCallback = response => {
    setCaptchaVerified(true);
    setCaptchaToken(response);
  };

  const recaptchaExpiredCallback = _response => {
    setCaptchaVerified(false);
    setCaptchaToken("");
  };

  function onDropdownChange(e) {
    if (e.target.value === OTHER_DROPDOWN_VALUE) {
      setIsOther(!isOther);
    } else {
      setIsOther(false);
    }
  }

  return (
    <Page title="eStatement Customer Service" layout="default">
      <Heading style={{ textAlign: "center" }} level={1}>
        eStatement Customer Service
      </Heading>

      <div style={{ textAlign: "center" }}>
        <p>
          Use this form if you have questions or concerns about your eStatement
          account.
        </p>
        <p>
          If you are not on eStatements,{" "}
          <Link href="/estatement-communities">click here</Link> to see if you
          are eligible.
        </p>
      </div>

      <form
        method="POST"
        action={`${
          process.env.GATSBY_HMCMGT_API_GATEWAY_URL
        }/estatement-inquiry`}
      >
        <hr />
        <div>
          <label htmlFor="request_type">
            I Am Requesting Assistance with the Following
          </label>
          <Dropdown
            id="request_type"
            name="assistance_with"
            defaultValue=""
            required
            onChange={onDropdownChange}
          >
            <option value="" disabled>
              Select One
            </option>
            {/* TODO: REPLACE OPTIONS */}
            <option value="Enrolling for the eStatement Service">
              Enrolling for the eStatement Service
            </option>
            <option value="My eStatement Account is Locked">
              My eStatement Account is Locked
            </option>
            <option value="Forgot My User ID or Password">
              Forgot My User ID or Password
            </option>
            <option value="Can't View my eStatement">
              {`Can't View my eStatement`}
            </option>
            <option value="Other">Other</option>
          </Dropdown>
        </div>

        <fieldset>
          <legend>Owner Details</legend>

          <div>
            <div className="input--split">
              <label htmlFor="first_name">First Name</label>
              <Input id="first_name" name="first_name" required />
            </div>
            <div className="input--split">
              <label htmlFor="last_name">Last Name</label>
              <Input id="last_name" name="last_name" required />
            </div>
          </div>

          <div>
            <label htmlFor="association">Association Name</label>
            <Input id="association" name="association_name" required />
          </div>

          <div>
            <label htmlFor="unit_number">Unit Number</label>
            <Input id="unit_number" name="unit_number" required />
          </div>

          <div>
            <label htmlFor="account_number">Hawaiiana Account Number</label>
            <Input
              id="account_number"
              name="hawaiiana_account_number"
              style={{ margin: "0" }}
              required
            />
            <InputHelper
              id="account-number-example"
              label="Need help locating your account number?"
              style={{ margin: "0 0 1rem 0" }}
              cb={togglePopup}
            >
              <img src={data.accountNumberSample.publicURL} />
            </InputHelper>
          </div>

          <div>
            <label htmlFor="phone_number">Phone Number</label>
            <Input id="phone_number" name="phone_number" type="tel" required />
          </div>

          <div>
            <label htmlFor="email_address">Email Address</label>
            <Input id="email_address" name="email_address" required />
          </div>

          <div>
            <label htmlFor="other_description">
              If Other, Please Provide a Description of the Problem
            </label>
            <Input
              id="other_description"
              type="textarea"
              name="description"
              required={isOther}
            />
          </div>

          <div>
            <label htmlFor="additional_comments">Additional Comments</label>
            <Input id="additional_comments" type="textarea" name="comments" />
          </div>
        </fieldset>
        <div id="recaptcha-container">
          {typeof window !== "undefined" && (
            <Recaptcha
              elementId="recaptcha-container"
              render="explicit"
              sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
              verifyCallback={recaptchaVerifyCallback}
              onloadCallback={console.debug}
              expiredCallback={recaptchaExpiredCallback}
            />
          )}
        </div>
        <Input
          id="captcha_token"
          name="captcha_token"
          type="hidden"
          value={captchaToken}
          required
        />

        {isCaptchaVerified && (
          <p style={{ textAlign: "center" }}>
            <Button type="submit">Submit</Button>
          </p>
        )}
      </form>
    </Page>
  );
};

export default EStatmentCustomerServiceForm;
